import React from "react";
import { Col, Container, Row } from "reactstrap";
import ActiePakket from "../components/general/ActiePakket";
import Box from "../components/general/Box";
import { H1, H2, P } from "../components/general/Typography";
import BannerBackground from "../components/home/BannerBackground";
import Layout from "../components/layout/Layout";
import Button from "../components/general/Button";
import Flex from "../components/general/Flex";
import Head from "../components/general/Head";
import PakkettenLijst from "../components/pakketten/PakkettenLijst";
import { overigePakketten, pakketten as pakkettenInfo } from "../components/pakketten/pakketInfo";

interface Props {}

const pakketten: React.FC<Props> = () => {
  return (
    <Layout>
      <Head page="pakketten" />

      <BannerBackground height="auto">
        <Container>
          <Row>
            <Col xs="12">
              <Box text-center py="5">
                <H1 background="white" color="blue" upcase>
                  Rijlessen bij Rijschool Lamar
                </H1>
              </Box>

              <Flex mt="4" justify-content="center">
                <ActiePakket darkMode />
              </Flex>

              <Box my="5" pb="3" text-center>
                <Button to="/contact?message=Hoi Amar, <br><br>Ik wil graag een afspraak maken!">
                  Maak nu een afspraak!
                </Button>
              </Box>
            </Col>
          </Row>
        </Container>
      </BannerBackground>

      <Container>
        <Row>
          <Col xs="12" md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
            <Box text-center mt="5" mb="4">
              <H2 fontSize="xxl" color="blue">
                Onze pakketten
              </H2>

              <P light className="my-4">
                Welke voordelige pakket prijzen hanteren we bij Rijschool Lamar?
                Uiteraard heb je recht op een gratis proefles. In de proefles
                zullen we alles bespreken en worden al jouw vragen beantwoord.{" "}
              </P>

              <Box mt="5">
                <Button to="/contact?message=Hoi Amar, <br><br>Ik wil graag een gratis proefles aanvragen!">
                  Gratis proefles!
                </Button>
              </Box>
            </Box>
          </Col>

          <Col xs="12">
            <Box my="5">
              <PakkettenLijst pakketten={pakkettenInfo} overigePakketten={overigePakketten} />
            </Box>
          </Col>

          <Col xs="12" md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
            <Box mt="1" mt-md="3" mb="5" pb="4" text-center>
              <P bold fontSize="large">
                Heb je nog vragen of weet je nog niet welk pakket bij je past?{" "}
              </P>

              <P className="my-4" light>
                Neem contact op met Amar en plan een gratis proefles in! Door
                Amar’s jarenlange ervaring als instructeur weet hij na een korte
                kennismaking precies welk pakket bij je past!
              </P>

              <Box mt="5">
                <Button to="/contact?message=Hoi Amar, <br><br>Ik wil graag een afspraak maken!">
                  Maak een afspraak!
                </Button>
              </Box>

              <Box mt="5">
                <P light>* Bij het annuleren van het praktijkexamen worden €25 aan administratiekosten in rekening gebracht.</P>
              </Box>
            </Box>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default pakketten;
