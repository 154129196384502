export type Pakket = {
  title: string;
  perks: string[];
  price: string;
  note?: string;
};

export const pakketten: Pakket[] = [
  {
    title: "A1",
    perks: [
      "10 Rijlessen €57,99 per uur (60 minuten)",
      "Proefexamen",
      "Praktijk examen",
    ],
    price: "879,99",
    note:
      "Uitsluitend voor mensen die wisselen van rijschool of herexamen doen. Het herexamen is gratis mits 8 lessen worden afgenomen.",
  },
  {
    title: "A2",
    perks: [
      "20 Rijlessen €56,99 per uur (60 minuten)",
      "Tussentijdse toets",
      "Praktijk examen",
      "BESPAAR €20",
    ],
    price: "1679,99",
  },
  {
    title: "A3",
    perks: [
      "30 Rijlessen €55,99 per uur (60 minuten)",
      "Tussentijdse toets",
      "Praktijk examen",
      "BESPAAR €30",
    ],
    price: "2219,99",
  },
  {
    title: "A4",
    perks: [
      "40 Rijlessen €54,99 per uur (60 minuten)",
      "Tussentijdse toets",
      "Praktijk examen",
      "BESPAAR €40",
    ],
    price: "2739,99",
  },
  {
    title: "Faalangst pakket",
    perks: [
      "50 Rijlessen €54,99 per uur (60 minuten)",
      "Tussentijdse toets",
      "Praktijkexamen faalangst",
      "Individueel theorie-examen",
      "Theorie boeken en leermateriaal",
      "Speciale begeleiding door een gecertificeerde faalangst instructeur",
    ],
    price: "3289,99",
  },
];

export type OverigPakket = {
  title: string;
  price: string;
};

export const overigePakketten: OverigPakket[] = [
  {
    title: "Losse les schakelauto (zonder pakket)",
    price: "57,99",
  },
  {
    title: "Losse les automaat (zonder pakket)",
    price: "55",
  },
  {
    title: "Praktijkexamen",
    price: "300",
  },
  {
    title: "Tussentijdse toets",
    price: "240",
  },
  {
    title: "Faalangst examen",
    price: "390",
  },
  {
    title: "Rijvaardigheidsonderzoek examen B-NO",
    price: "400",
  },
];
